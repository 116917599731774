<template>
  <modal-layout @close="$emit('close')" title="Пользовательское соглашение">
    <div class="p-3">
      <p>
        Осы Өтініш-сауалнамаға қол қою арқылы «Халық»" сақтандыру компаниясы» АҚ – қа, БСН 981040001082 (бұдан әрі-Сақтандырушы) қамтуы мүмкін (қоса алғанда, бірақ онымен шектелмей) өзімнің дербес
        деректерін жинауға және өңдеуге келісім беремін: / Подписанием настоящего Заявления-анкеты даю АО «Страховая компания «Халык», БИН 981040001082, (далее – Страховщик) согласие
        на сбор и обработку своих персональных данных, которые могут содержать (включая, но не ограничиваясь):
        1. Сәйкестендіру/аутентификациялау/авторизациялау үшін қажетті мәліметтер: тегі, аты, әкесінің аты (егер ол жеке басты куәландыратын құжатта көрсетілсе),
        азаматтығы, жеке басты куәландыратын құжаттың деректері, жеке сәйкестендіру нөмірі, туған күні мен жері, тұрғылықты жері, заңды мекенжайы, телефон нөмірі, электрондық пошта мекенжайы,
        бейнесі, дауысты жазу. / Сведения, необходимые для идентификации/аутентификации/авторизации: фамилия, имя, отчество (если оно указано в документе, удостоверяющем личность), гражданство,
        данные документа, удостоверяющего личность, индивидуальный идентификационный номер, дата и место рождения, место жительства, юридический адрес, номер телефона, адрес электронной почты, изображение, запись голоса.
      </p>
      <p>
        2. Өмірі мен денсаулығына келтірілген зиянды өтеу бөлігінде сақтандыру төлемін жүзеге асыру жөніндегі міндеттемелерді
        орындау үшін қажетті мәліметтер: денсаулық жағдайы, алынған медициналық қызметтер, консультациялардың бейнежазбасы,
        медициналық көмекке жүгіну фактісі туралы ақпарат, оның ауруының диагнозы және оны тексеру мен емдеу кезінде алынған өзге де мәліметтер.
        / Сведения, необходимые для исполнения обязательств по осуществлению страховой выплаты в части возмещения вреда, причиненного жизни и здоровью: состояние здоровья,
        полученные медицинские услуги, видеозапись консультаций, информация о факте обращения за медицинской помощью, диагноз его заболевания и иные сведения, полученные при его обследовании и лечении.
      </p>
      <p>
        Дербес деректерді жинау мен өңдеудің мақсаты Сақтандырушының сақтандыру туралы шарт бойынша өз міндеттемелерін орындауы
        болып табылады. / Целью сбора и обработки персональных данных является исполнение Страховщиком своих обязательств по договору о страховании.
        Ондай-ақ Қызмет көрсету сапасын жақсарту және маркетингтік бағдарламаларды жүргізу мақсатында осымен Сақтандырушының қызметтері
        туралы ақпарат алуға, телефон қоңыраулары, sms-хабарламалар, push-хабарламалар және мен көрсеткен электрондық хаттар түрінде сауалнама
        жүргізуге өз келісімімді беремін. / Также для целей улучшения качества обслуживания и проведения маркетинговых программ настоящим даю свое согласие на
        получение информации об услугах Страховщика, на проведение опросов в виде телефонных звонков, sms-сообщений, push-сообщений и электронных писем на указанный мною
        номер телефона и/или адрес электронной почты.
      </p>
      <p>
        Мен Сақтандырушыға өзімнің дербес деректерімді үшінші тұлғаларға (оның ішінде компанияның үлестес тұлғаларына, сақтандыру
        нарығының кәсіби қатысушыларына және сақтандыру және/немесе маркетингтік қызметтер шартының талаптарын орындау шеңберінде
        қатысатын өзге де тұлғаларға) беруге, оның ішінде трансшекаралық беруге келісім беремін. / Я даю согласие Страховщику на передачу,
        в том числе трансграничную, своих персональных данных третьим лицам (в том числе аффилированным лицам Компании, профессиональным
        участникам страхового рынка и иным лицам, участвующим в рамках исполнения условий договора страхования и/или маркетинговых услуг).
      </p>
      <p>
        Осы Келісім сақтандырушы айқындаған мақсаттарға қол жеткізгенге дейінгі мерзімге берілді және егер Қазақстан Республикасының
        заңнамасында өзгеше көзделмесе, оны кері қайтарып алу сәтіне дейін қолданылады.  / Настоящее согласие выдано на срок до достижения
        целей, определенных Страховщиком, и действует до момента его отзыва, если иное не предусмотрено законодательством Республики Казахстан.
        Сақтандырушы ұсынылған дербес деректердің шынайылығын басқа қолжетімді ақпарат көздерін пайдаланып тексеруге құқылы. / Страховщик вправе
        проверить достоверность предоставленных персональных данных, в том числе с использованием других доступных источников.
      </p>
      <p>
        Осымен өтініш-сауалнамада көрсетілген мәліметтердің дұрыс екендігін және жалған ақпараттан немесе белгілі бір дәлсіздіктерден
        тұрмайтындығын және күллі маңызды ақпаратты Сақтандырушы талабы бойынша өзімнің ұсынғандығымды мәлімдеймін. Сонымен қатар, сақтандыру
        объектісі, сақтаныру тәуекелі, сақтандыру жағдайы және оның салдары туралы жалған мәліметтер хабаралағаным үшін болатын салдарлар туралы маған мәлім.
        / Настоящим заявляю, что сведения, указанные в Заявлении, верны и не содержат ложной информации или каких-либо неточностей, и вся существенная информация
        в соответствии с требованием Страховщика мною предоставлена.
        Мне также известно о последствиях за сообщение ложных сведений об объекте страхования, страховом риске, страховом случае и его последствиях.
      </p>

      С Правилами страхования АО «Страховая компания «Халык» ознакомлен(а) и их копию получил(а).


      Уведомление. Страховщик оставляет за собой право расторгнуть полис и/или отказать в осуществлении страховой выплаты в случае, если Страхователь настоящим привел ложные сведения либо утаил какую бы то ни было существенную информацию в целях заключения полис, а также по основаниям, предусмотренным Законом РК «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма».
    </div>
    <button class="bottom-page-btn primary-btn" @click="$emit('close')">Закрыть</button>
  </modal-layout>
</template>

<script>
export default {
  name: "TermsModal"
}
</script>

<style scoped>

</style>
