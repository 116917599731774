<template>
  <secondary-page-layout title="Регистрация">
    <div class="registration" v-if="!showVerify">
      <ValidationObserver ref="form" v-slot="{invalid}">
        <div class="form-inner">
          <div class="form-group">
            <input-field
                label="Номер телефона"
                v-model="form.phone_number"
                field-type="tel"
                :is-block="true"
                rules="required|min:10"
                name="phone_number"
                mask="+7(###) ###-##-##"/>
            <input-field
                label="ИИН"
                field-type="tel"
                rules="required|min:12"
                :is-block="true"
                name="iin"
                v-model="form.individual_id_number"
                mask="############"/>

            <input-field
                label="Email"
                field-type="email"
                :is-block="true"
                name="email"
                addition="Поле для заполнения 'Email' необязательно"
                v-model="form.email"/>
            <input-field
                label="Пароль"
                field-type="password"
                rules="required|min:8"
                :is-block="true"
                name="password"
                addition="*Пароль должен содержать минимум 8 символов"
                v-model="form.password"/>
            <div class="d-flex">
              <ValidationProvider :rules="{ required: { allowFalse: false } }" name="terms" v-slot="{errors}">
                <input type="checkbox" id="terms" class="me-4" name="terms" v-model="form.terms">
              </ValidationProvider>
              <label for="terms" class="terms-text">Я принимаю условия <span style="color: #2aa65c;" @click.prevent="showTermsModal">пользовательского соглашения</span> и даю свое согласие на сбор и обработку персональных данных</label>
            </div>
            <span v-if="error" class="text-danger">{{ error }}</span>
          </div>
          <v-dialog class="error"/>
          <div class="buttons-wrapper">
            <button class="primary-btn" @click="onSubmit" :disabled="invalid">Зарегистрироваться</button>
            <router-link to="/login" class="bordered-btn">Назад на страницу входа</router-link>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <otp-verify v-else v-model="code" @submit="verifyCode" @sendCode="sendConfirmCode" :error-message="errorMessage"></otp-verify>
  </secondary-page-layout>
</template>

<script>
import {mapActions} from "vuex";
import InputField from "../../components/BuyPolicy/InputField";
import eventLogger from '../../services/eventLogger'
import OtpVerify from "../../components/OTP/OtpVerify";
import {generateRegistrationOtp, verifyRegistrationOtp} from "../../api/otp";
import RegistrationErrorModal from "../../components/Modals/RegistrationErrorModal";
import TermsModal from "../../components/Modals/TermsModal";


export default {
  name: "Registration",
  components: {
    InputField,
    OtpVerify,
    RegistrationErrorModal
  },
  data() {
    return {
      form: {
        phone_number: '+7(',
        individual_id_number: '',
        email: '',
        password: '',
        token: '',
        terms: false
      },
      error: null,
      showVerify: false,
      code: '',
      errorMessage: null,
    }
  },
  mounted(){
    eventLogger('view_sign_up_screen')
  },
  methods: {
    ...mapActions(['signUp']),

    showTermsModal() {
      this.$modal.show(TermsModal, {}, this.$modalConfig);
    },

    onSubmit() {
      this.$refs.form.validate().then((success) => {
        // if (success) this.register();
          if(success) {
            this.checkPhoneAndIinPair();
          }
      });
    },
    async sendConfirmCode() {
      const loader = this.$loading.show();

      try {
        await generateRegistrationOtp(this.form.phone_number);
        // alert(response.code);
      } catch (e) {
        throw e
      } finally {
        loader.hide();
      }
    },
    async verifyCode(code) {
      let loader = this.$loading.show();
      try {
        await verifyRegistrationOtp(this.form.phone_number, code);
        await this.register();
      } catch (err) {
        const { data, status } = err.response
        if (status === 400) {
          this.errorMessage = data.message;
        }
      } finally {
        loader.hide()
      }
    },
    async checkPhoneAndIinPair() {
      try {
        await window.axios.post('/auth/validate-user',
            {
              individual_id_number: this.form.individual_id_number,
              phone_number: this.form.phone_number
            }
        );
        await this.sendConfirmCode();
        this.showVerify = true;
      } catch (err) {
        const {data, status} = err.response;
        if(status === 422) {
          const errorsList = Object.values(data.errors);
          const errors = [].concat.apply([], errorsList);
          // this.$modal.show(RegistrationErrorModal, {errorsList: errors}, this.$modalConfig);
          this.$modal.show('dialog', {
            title: 'Ошибка',
            text: errors.join('\n'),
            buttons: [
              {
                title: 'Закрыть',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
          });
        } else {
          // this.$modal.show(RegistrationErrorModal, {singleError: data.message}, this.$modalConfig);
          this.$modal.show('dialog', {
            title: 'Ошибка',
            text: data.message,
            buttons: [
              {
                title: 'Закрыть',
                handler: () => {
                  this.$modal.hide('dialog')
                }
              },
            ]
          });
        }

        throw err;
      }
    },
    async register() {
      let loader = this.$loading.show({});

      try {
        this.form.token = window.DEVICE_ID
        await this.signUp(this.form)
        eventLogger('sign_up_success')
        window.location.reload()
      } catch (e) {

        const {status, data} = e.response;

        // this.$modal.show(RegistrationErrorModal, {text: 'Ошибка сервера'}, this.$modalConfig);
        this.$modal.show('dialog', {
          title: 'Ошибка',
          text: 'Ошибка сервера',
          buttons: [
            {
              title: 'Закрыть',
              handler: () => {
                this.$modal.hide('dialog')
              }
            },
          ]
        });

        //Logging events
        if (status === 422) {
          eventLogger('sign_up_form_fail', {error_description: data?.message || 'undefined'})
          this.error = "Аккаунт с таким номером телефона уже существует";
        } else {
          eventLogger('sign_up_fail', {error_description: data?.message || 'undefined'})
        }
      } finally {
        loader.hide();
      }
    },
  }
}
window.setDeviceId = function(DEVICE_ID) {
  window.DEVICE_ID = DEVICE_ID
}
</script>

<style lang="scss" scoped>
.registration {
  padding-top: 16px;
  padding-bottom: 16px;
  height: 100%;
  .title {
    font-weight: bold;
    text-align: center;
    margin-bottom: 36px;
  }
  .form-group {
    .primary-block {
      padding: 24px;
    }
    .secondary-text {
      font-size: 14px;
    }
  }
}
.form-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.buttons-wrapper {
  width: 100%;
  padding: 16px;
  .primary-btn {
    width: 100%;
    margin-bottom: 12px;
    z-index: 20;
  }
  .bordered-btn {
    width: 100%;
    z-index: 20;
  }
}
.terms-text {
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: #99a3b3;
}
</style>
